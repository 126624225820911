import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Row, Col } from 'react-styled-flexboxgrid'

import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import Section from '../components/common/Section'
import Divider from '../components/common/Divider'
import { SectionContainer } from '../components/common/Index.styled'

import sms_background from '../images/sms_background.jpeg'
import logoSMS from '../images/logo-SMS-latinoamerica.jpg'
import logoBlack from '../images/logo_as_black.png'
import smsLatamIcon from '../images/sms_latam.png'
import forum_of_firms from '../images/forum_of_firms.png'

const SmsMexico = props => (
  <Layout
    title="SMS México"
    description="A partir del 2017 AS Consulting fue designada Firma Corresponsal de SMS Latinoamérica para la Ciudad de México, Benito Juárez."
    {...props}
  >
    <SectionHeaderImage image={sms_background} position="0" />

    <Divider border="left" small>
      <h1 style={{ fontSize: '18px' }}>
        <FormattedMessage id="aboutUs.corresponsal.title" />
      </h1>
    </Divider>
    <Section height="400px">
      <SectionContainer>
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: '320px',
            margin: '0 1rem',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <img src={logoBlack} alt="AS Consulting logo" height={50} />
          <p style={{ margin: '0 12px' }}>Miembro</p>
          <img src={logoSMS} alt="SMS Lationamerica" height={90} />
        </div>
        <div style={{ maxWidth: '600px', margin: '0 1rem' }}>
          <p>
            <FormattedHTMLMessage id="aboutUs.corresponsal.p1" />
          </p>
        </div>
      </SectionContainer>
    </Section>
    <Row>
      <Col xs={12} md={8} lg={9}>
        <Divider border="right" small>
          <h2>SMS LATIONAMÉRICA</h2>
        </Divider>
      </Col>
      <Col xs={12} md={4} lg={3}>
        <Row center="xs" middle="xs" style={{ padding: '1rem 0' }}>
          <img src={smsLatamIcon} alt="SMS Lationamerica" height={90} />
          <p style={{ color: '#A2A2A2', margin: 0 }}>
            <FormattedHTMLMessage id="aboutUs.sms.footer_image" />
          </p>
        </Row>
      </Col>
    </Row>
    <Section height="500px">
      <SectionContainer>
        <div style={{ maxWidth: '600px', margin: '0 1rem' }}>
          <div style={{ textAlign: 'center' }}>
            <a href=" http://www.smslatam.com" target="_blank">
              <img src={logoSMS} alt="SMS Lationamerica" height={80} />
            </a>
          </div>
          <p>
            <FormattedMessage id="aboutUs.sms.p1" />
          </p>
          <FormattedMessage
            id="aboutUs.sms.footer"
            style={{ fontSize: 'small' }}
          />
          <a
            href=" http://www.smslatam.com"
            target="_blank"
            style={{ marginLeft: '5px' }}
          >
            http://www.smslatam.com
          </a>
        </div>
        <div style={{ maxWidth: '600px', margin: '0 1rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '96px',
            }}
          >
            <img src={forum_of_firms} alt="SMS Lationamerica" height={60} />
          </div>
          <p>
            <FormattedMessage id="aboutUs.forum_firms.p1" />
          </p>
          <p>
            <FormattedMessage id="aboutUs.forum_firms.p2" />
          </p>
        </div>
      </SectionContainer>
    </Section>
  </Layout>
)

export default SmsMexico
